import {
  Box,
  Image,
  Card,
  CardBody,
  Center,
  FormControl,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Progress,
  Link,
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import Countdown from "../components/Timer";
import { useEffect, useState } from "react";
import BNBLogo from "../assets/icons/bnb.svg";
import PeeperLogo from "../assets/icons/peeper.png";
import { UpDownIcon } from "@chakra-ui/icons";
import SwapIcon from "../assets/icons/swap.svg";
import { ConnectedWallet } from "../components/connected-wallet";
import { useBuyTokens, useRate, useTokensAllocated } from "../services/PeeperSale";
import { EthToWei, WeiToEth } from "../utils";
import { useAccount, useBalance } from "wagmi";
import { usePeeperBalance } from "../services/PeeperToken";
import { WithdrawForm } from "./withdraw";

export const Form = () => {
  const [rate, setRate] = useState(0);
  const [bnbValue, setBnbValue] = useState("100");
  const [peeperValue, setPeeperValue] = useState(
    (Number(bnbValue) * rate).toString()
  );
  const target = 1_000_000
  const {address} = useAccount()
  const {data: balanceData, isLoading: isLoadingBalance} = useBalance({address})
  const {data: rateData, isLoading: isLoadingRate} = useRate()
  const {data: tokensAllocatedData, isLoading: isTokenAllocatedLoading} = useTokensAllocated()
  const {data: peeperBalanceData} = usePeeperBalance({account: address ?? ""})
  const {write, isLoading: writeLoading, data: writeData} = useBuyTokens({value: EthToWei(Number(bnbValue).toFixed(10) ?? "0").toString()})
  const {isOpen, onClose, onOpen} = useDisclosure()

  useEffect(() => {
    if(rateData) {
      setRate(Number(BigInt(rateData as string)))
      setPeeperValue((Number(BigInt(rateData as string)) * Number(bnbValue)).toString())
    }
  }, [rateData])

  useEffect(() => {
    if(balanceData && rateData) {
      const balance = Number(balanceData.formatted) * 0.9
      setBnbValue(balance.toString())
      setPeeperValue(
        balanceData.formatted !== ""
          ? String(
              balance * Number(rateData)
            )
          : ""
      );
    }
  }, [balanceData])

  useEffect(() => {
    if(writeData && writeData.hash) {
      onOpen()
    }
  }, [writeData])

  const handleBuy = async () => {
    if(write) write()
  }

  const InputFormData = () => {

    if(isLoadingRate || isTokenAllocatedLoading || isLoadingBalance) {
      return(
        <Center w={'100%'}>
          <Spinner size={'lg'}/>
        </Center>
      )
    }

    return (
      <>
      <HStack>
        <Heading size={"md"} fontFamily={"gotham-rounded-bold"}>
              {(WeiToEth(tokensAllocatedData as string) + 124200)} / {target}
            </Heading>
            <Image src={PeeperLogo} w={6}/>
            </HStack>
            <Progress
              w="100%"
              size="lg"
              hasStripe
              isAnimated
              colorScheme="orange"
              value={Math.ceil(((WeiToEth(tokensAllocatedData as string) + 124200) / target) * 100) ?? 1}
              borderRadius={10}
            />
            <VStack>
              <Box borderRadius={40} bg={"gray.600"} padding={2} paddingTop={5}>
                <FormControl w={"100%"} variant="outlined">
                  <InputGroup>
                    <Input
                      fontFamily={"gotham-rounded-bold"}
                      type="number"
                      id="BNB_INPUT"
                      variant="flushed"
                      paddingRight={"5rem"}
                      fontSize={30}
                      value={bnbValue}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Verifica se o valor está vazio ou é um número positivo
                        if (
                          value === "" ||
                          (/^\d+([.,]\d*)?$/.test(value) &&
                            parseFloat(value.replace(",", ".")) >= 0)
                        ) {
                          setBnbValue(value);
                          setPeeperValue(
                            value !== ""
                              ? String(
                                  parseFloat(value.replace(",", ".")) * Number(rate)
                                )
                              : ""
                          );
                        }
                      }}
                    />
                    <InputRightElement w={"4.5rem"}>
                      <HStack w={"100%"} marginBottom={2} alignItems={"center"}>
                        <Text fontFamily={"gotham-rounded-bold"}>BNB</Text>
                        <Image src={BNBLogo} w={8} h={8} />
                      </HStack>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <HStack justifyContent={"flex-end"} w={"100%"} paddingRight={2}>
                  <Text fontFamily={"gotham-rounded-bold"}>Balance: {Number(balanceData?.formatted ?? "0").toFixed(6) ?? 0}</Text>
                  <Link onClick={() => {
                    if(balanceData) {
                      setBnbValue(balanceData.formatted)
                      setPeeperValue(
                        balanceData.formatted !== ""
                          ? String(
                              parseFloat(balanceData.formatted.replace(",", ".")) * Number(rate)
                            )
                          : ""
                      );
                    }
                  }} color={"orange"} fontFamily={"gotham-rounded-bold"}>
                    Max
                  </Link>
                </HStack>
              </Box>
              <HStack
                marginY={"-1.8rem"}
                zIndex={1000}
                w={"100%"}
                justifyContent={"center"}
              >
                <Box
                  border={"1px solid black"}
                  bg={"gray.600"}
                  padding={2}
                  borderRadius={25}
                >
                  <UpDownIcon w={6} h={6} />
                </Box>
              </HStack>
              <Box bg={"gray.600"} padding={2} borderRadius={40} paddingTop={5}>
                <FormControl w={"100%"} variant="outlined">
                  <InputGroup>
                    <Input
                      fontFamily={"gotham-rounded-bold"}
                      type="number"
                      id="BNB_INPUT"
                      variant="flushed"
                      paddingRight={"7rem"}
                      fontSize={30}
                      value={peeperValue}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Verifica se o valor está vazio ou é um número positivo
                        if (
                          value === "" ||
                          (/^\d+([.,]\d*)?$/.test(value) &&
                            parseFloat(value.replace(",", ".")) >= 0)
                        ) {
                          setPeeperValue(value);
                          setBnbValue(
                            value !== ""
                              ? String(
                                  parseFloat(value.replace(",", ".")) / Number(rate)
                                )
                              : ""
                          );
                        }
                      }}
                    />
                    <InputRightElement w={"6.4rem"}>
                      <HStack w={"100%"} marginBottom={2} alignItems={"center"}>
                        <Text fontFamily={"gotham-rounded-bold"}>PEEPER</Text>
                        <Image src={PeeperLogo} w={8} h={8} />
                      </HStack>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <HStack paddingRight={2} justifyContent={"flex-end"} w={"100%"}>
                  <Text fontFamily={"gotham-rounded-bold"}>Balance: {WeiToEth(peeperBalanceData as string ?? "0").toString()}</Text>
                </HStack>
              </Box>
            </VStack>
      </>
    )
  }

  return (
    <VStack w={"100%"} alignItems={"center"}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={35}>
          <ModalBody color={'white'} borderRadius={30} bg={'gray.700'}>
            <VStack>
              <VStack className="bounceObj" padding={10}>
                  <Heading fontFamily={'gotham-rounded-bold'}>Congratulations!!</Heading>
                  <Text fontSize={'100px'}>🎉</Text>
              </VStack>
              <Button as={'a'} target="_blank" href={`https://bscscan.com/tx/${writeData?.hash}`} fontFamily={'gotham-rounded-bold'} size={'lg'} colorScheme="orange">Check Tx</Button>
              <Heading textAlign={'center'} color={'orange'} size={'sm'}>Once the transaction has been confirmed, refresh the page and you will see the Peepers allocated to your wallet and the vesting period for withdrawing them</Heading>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        borderColor={"greenyellow"}
        borderWidth={"2px"}
        marginBottom={-10}
        borderRadius={30}
        zIndex={1000}
        bg={"gray.600"}
        w={"80%"}
        h={"50px"}
      >
        <Center>
          <Heading color="greenyellow">ROUND 1</Heading>
        </Center>
      </Box>
      <Card borderRadius={30} w={"100%"} bg={"gray.700"}>
        <CardBody color={"white"}>
          <VStack
            spacing={5}
            marginTop={22}
            w={"100%"}
            alignItems={"flex-start"}
          >
            <Heading size={"md"} fontFamily={"gotham-rounded-bold"}>
              Round ends in:
            </Heading>
            <Countdown targetDate={new Date("February 25, 2024 23:59:59")} />
            <HStack marginTop={-10} alignItems={"baseline"} wrap={"wrap"}>
              <Heading fontFamily={"gotham-rounded-bold"} size={"sm"}>
                In next round the price will grow up
              </Heading>
              <Heading
                fontFamily={"gotham-rounded-bold"}
                color={"red"}
                size={"lg"}
                className="intermitente"
              >
                2.5x
              </Heading>
            </HStack>
            {InputFormData()}
            <ConnectedWallet networkId={56}>
              <Button
                rightIcon={<Image src={SwapIcon} w={8} h={8} />}
                borderRadius={30}
                fontFamily={"gotham-rounded-bold"}
                fontSize={"x-large"}
                h={"4rem"}
                colorScheme="orange"
                w={"100%"}
                onClick={handleBuy}
                isLoading={writeLoading}
                isDisabled={!bnbValue || bnbValue.length == 0 || Number(bnbValue) <= 0 || !write}
              >
                Swap
              </Button>
            </ConnectedWallet>
          </VStack>
        </CardBody>
      </Card>
      <WithdrawForm />
    </VStack>
  );
};
