import {
  Box,
  Image,
  Center,
  HStack,
  Heading,
  SlideFade,
  Text,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { NotLoggedNavBar } from "../components/navbar";
import { useState, useEffect } from "react";
import MainIllustration from "../assets/main illustration.png";
import Rocket from "../assets/rocket.png";
import Planet from "../assets/planet.png";
import TokensImage from "../assets/tokens.png";
import Books from "../assets/books.png";
import Networking from "../assets/networking.png";
import Anouncement from "../assets/announcement.png";
import Viking from "../assets/viking.png";
import RaidArrow from "../assets/raid arrow.png";
import HowItWorks from "../assets/how it works illustration.png";
import Deposit from "../assets/deposit.png";
import Reward from "../assets/reward.png";
import Scale from "../assets/scale.png";
import Boost from "../assets/boost.png";
import { Form } from "../components/buy-form";

const StepCard = (props: {
  image: string;
  title: string;
  text: string;
  number: string;
}) => {
  return (
    <VStack maxW={"20em"} color={"white"} justifyContent={"center"}>
      <Image src={props.image} zIndex={1000} />
      <Box
        borderRadius={30}
        bg={"rgba(79, 133, 205, 0.8)"}
        paddingTop={16}
        marginTop={-16}
      >
        <HStack marginLeft={-5} marginTop={-10}>
          <Box
            bg={"orange"}
            borderRadius={"full"}
            fontFamily={"gotham-rounded-bold"}
            fontSize={"30px"}
            w={10}
            h={10}
          >
            <Center>{props.number}</Center>
          </Box>
        </HStack>
        <VStack
          paddingBottom={5}
          paddingX={10}
          w={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"100%"}
        >
          <Heading
            textAlign={"center"}
            fontFamily={"gotham-rounded-bold"}
            size={"md"}
          >
            {props.title}
          </Heading>
          <Text textAlign={"center"} opacity={0.8}>
            {props.text}
          </Text>
        </VStack>
      </Box>
    </VStack>
  );
};

export const HomePage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <VStack color={"white"} minH={"100vh"} w={"100%"}>
      <NotLoggedNavBar />
      <VStack w={"100%"} maxW={"1500px"} padding={isMobile ? 2 : 5}>
        <HStack
          justifyContent={isMobile ? "center" : "space-between"}
          alignItems={"center"}
          w={"100%"}
          wrap={isMobile ? "wrap" : "nowrap"}
        >
          <SlideFade in={true} offsetX={"-200px"} delay={0.2}>
            <VStack w={"100%"} alignItems={"flex-start"}>
              <HStack alignItems={"baseline"} wrap={"wrap"}>
                <Heading fontFamily={"gotham-rounded-bold"}>The First</Heading>
                <Heading fontFamily={"gotham-rounded-bold"} color={"orange"}>
                  SocialFi LauchPad
                </Heading>
              </HStack>
              <Image src={MainIllustration} />
              {/* <Text fontWeight={600} fontSize={'large'} maxW={'600px'} >
                    PEEPER is the first SocialFi LaunchPad, created to empower micro-influence. With peeper you are able to create any social activity to be paid to our participants, from simple things like liking a tweet, following a page, joining a telegram group, to things like boosting a hashtag, engaging users in your community, etc. With PEEPER, we've proved that micro-influence is much more powerful than traditional influence, easily breaking bubbles and giving traction to any idea!
                    </Text> */}
              <Heading fontFamily={"gotham-rounded-bold"}>
                Unparalleled growth and real audience engagement!
              </Heading>
              <Heading size={"lg"} opacity={"0.8"} fontWeight={"300"}>
                Promote your project, announce milestones, expand your
                community.
              </Heading>
            </VStack>
          </SlideFade>
          <SlideFade in={true} offsetX={"200px"} delay={0.2}>
            <HStack w={"100%"} justifyContent={"center"}>
              <VStack w={isMobile ? "22em" : "32rem"} alignItems={"flex-start"}>
                <Form />
              </VStack>
            </HStack>
          </SlideFade>
        </HStack>
        <Heading
          marginTop={5}
          w={"100%"}
          textAlign={"left"}
          transform={"rotate(-3deg)"}
          fontFamily={"CedarvilleCursive-Regular"}
          color={"orange"}
        >
          Why Peeper Campaings?
        </Heading>
        <HStack
          alignItems={"center"}
          wrap={"wrap"}
          justifyContent={"space-around"}
          marginTop={5}
        >
          <StepCard
            image={Rocket}
            number="1"
            title="Launch and Hype your Project/Token"
            text="Ideal for announcing new cryptocurrency launches. Create buzz around your project or token's unique features and potential"
          />
          <StepCard
            image={Planet}
            number="2"
            title="Community Engagement and Growth"
            text="Strengthen and expand your crypto community with Interactive campaigns that reward genuine engagement."
          />
          <StepCard
            image={TokensImage}
            number="3"
            title="Promote ICOs/IDOs"
            text="Use Peeper campaigns to increase visibility and participation in your initial Coin Offerings or initial DEX Offerings."
          />
          <StepCard
            image={Books}
            number="4"
            title="Educate and Inform"
            text="Share valuable insights about blockchain technology. updates and advancements in your Projects"
          />
          <StepCard
            image={Networking}
            number="5"
            title="Networking Events"
            text="Amplify the reach of your virtual meetups, AMAs (Ask Me Anything), and webinars, ensuring high engagement and turnout."
          />
          <StepCard
            image={Anouncement}
            number="6"
            title="Partnership Announcements"
            text="Highlight collaborations with other crypto projects or businesses, leveraging Peeper's network for maximum impact."
          />
          <HStack marginTop={isMobile ? 10 : ""}>
            <Image w={isMobile ? "36" : ""} src={Viking} />
            <HStack marginLeft={-6} marginTop={isMobile ? -32 : -40}>
              <Box
                bg={"orange"}
                borderRadius={"full"}
                fontFamily={"gotham-rounded-bold"}
                fontSize={"30px"}
                w={10}
                h={10}
              >
                <Center>7</Center>
              </Box>
            </HStack>
            <Image w={isMobile ? "36" : ""} src={RaidArrow} />
          </HStack>
        </HStack>
        <HStack w={"100%"} wrap={isMobile ? "wrap-reverse" : "nowrap"}>
          <VStack alignItems={"flex-start"}>
            <Heading fontFamily={"gotham-rounded-bold"}>How It Works</Heading>
            <Heading
              size={"lg"}
              fontFamily={"gotham-rounded-bold"}
              color={"orange"}
            >
              Create campaigns on Peeper Mint to boost your product releases,
              events, and brand awareness.
            </Heading>
            <Heading size={"lg"} opacity={"0.8"} fontWeight={"300"}>
              Deposit Peeper tokens to run campaigns, attracting real
              interactions from our engaged
            </Heading>
          </VStack>
          <Image src={HowItWorks} />
        </HStack>
        <HStack wrap={"wrap"} justifyContent={"space-around"} w={"100%"}>
          <VStack justifyContent={"center"} maxW={"20em"}>
            <Image src={Deposit} />
            <Text
              fontFamily={"gotham-rounded-bold"}
              fontSize={"larger"}
              textAlign={"center"}
              fontWeight={600}
            >
              Deposit Peeper Tokens to create Campaign
            </Text>
          </VStack>
          <VStack justifyContent={"center"} maxW={"20em"}>
            <Image src={Reward} />
            <Text
              fontFamily={"gotham-rounded-bold"}
              fontSize={"larger"}
              textAlign={"center"}
              fontWeight={600}
            >
              Peepers will be used to reward REAL interactions.
            </Text>
          </VStack>
          <VStack justifyContent={"center"} maxW={"20em"}>
            <Image src={Scale} />
            <Text
              fontFamily={"gotham-rounded-bold"}
              fontSize={"larger"}
              textAlign={"center"}
              fontWeight={600}
            >
              The more Peepers you deposit, the longer your campaign will run.
            </Text>
          </VStack>
        </HStack>

        <HStack w={"100%"} wrap={isMobile ? "wrap-reverse" : "nowrap"}>
          <VStack alignItems={"flex-start"}>
            <Heading fontFamily={"gotham-rounded-bold"}>
              Boost your posts!
            </Heading>
            <Heading
              size={"lg"}
              fontFamily={"gotham-rounded-bold"}
              color={"orange"}
            >
              Dynamic Scaling The more Peepers in your campaign, the longer it
              thrives!
            </Heading>
            <TableContainer padding={5} boxShadow={'2xl'} bg={'whitesmoke'} borderRadius={30} maxW={isMobile ? "22em" : "100%"}>
              <Table variant={"unstyled"}>
                <Thead>
                  <Tr>
                    <Th fontSize={"large"} fontWeight={500} opacity={0.6} color={"gray.500"}>
                      Peepers
                    </Th>
                    <Th fontSize={"large"} fontWeight={500} opacity={0.6} color={"gray.500"}>
                      Campaign Duration
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      25k...............
                    </Td>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      1 day
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      400k............
                    </Td>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      20 day
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      500k............
                    </Td>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      30 day
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      1M.................
                    </Td>
                    <Td
                      fontSize={"x-large"}
                      fontFamily={"gotham-rounded-bold"}
                      color={"gray.600"}
                      opacity={0.6}
                    >
                      75 day
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Text>
              * example of campaign duration, based on the current price of
              Peeper.
            </Text>
          </VStack>
          <Image src={Boost} />
        </HStack>
      </VStack>
    </VStack>
  );
};
