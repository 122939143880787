import React from 'react';
import { WagmiConfig } from 'wagmi';
import { config } from './config/wagmi';
import { ChakraProvider } from '@chakra-ui/react';
import { Text, Box, Flex } from '@chakra-ui/react';
import { HomePage } from './pages/Home';

function App() {
  return (
    <WagmiConfig config={config}>
      <ChakraProvider>
      <HomePage />
      <Box as="footer" bg="gray.900" color="white" py="4">
      <Flex justify="center">
        <Text>&copy; Peeper Mint. All rights reserved.</Text>
      </Flex>
    </Box>
      </ChakraProvider>
    </WagmiConfig>
  );
}

export default App;
