import { useContractRead, useContractWrite, usePrepareContractWrite } from "wagmi"
import { PEEPER_SALE } from "../config/constants"
import abi from '../abi/PeeperVesting.json'

export const useRate = () => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'rate'
    })

    return {data, isError, isLoading, refetch}
}

export const useWeiRaised = () => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'weiRaised'
    })

    return {data, isError, isLoading, refetch}
}

export const useTokensAllocated = () => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'tokensAllocated'
    })

    return {data, isError, isLoading, refetch}
}

export const useStartTime = () => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'startTime'
    })

    return {data, isError, isLoading, refetch}
}

export const useAllocated = (props: {address: string}) => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'allocated',
        args: [props.address]
    })
    return {data, isError, isLoading, refetch}
}

export const useAvailable = (props: {address: string}) => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'available',
        args: [props.address]
    })
    return {data, isError, isLoading, refetch}
}


export const useBuyTokens = (props: {value: string}) => {
    const {config} = usePrepareContractWrite({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'buyTokens',
        value: props.value as any
    })
    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}

export const useClaim = () => {
    const {config} = usePrepareContractWrite({
        address: PEEPER_SALE,
        abi: abi.abi,
        functionName: 'claim',
    })
    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}

