import { Text, Button, Card, CardBody, HStack, Heading, Image, Modal, ModalBody, ModalContent, ModalOverlay, VStack, useDisclosure } from "@chakra-ui/react"
import TokenImage from '../assets/tokens.png'
import { useAllocated, useAvailable, useClaim, useStartTime } from "../services/PeeperSale"
import { useAccount } from "wagmi"
import { WeiToEth } from "../utils"
import PeeperLogo from '../assets/icons/peeper.png'
import { useEffect, useState } from "react"
import Countdown from "./Timer"

export const ClaimButton = (props: {available: any}) => {
    const {write, data, isLoading} = useClaim()
    const {isOpen, onClose, onOpen} = useDisclosure()

    useEffect(() => {
        if(data?.hash) {
            onOpen()
        }
    }, [data])

    const handleClick = () => {
        if(write) write()
    }

    return (
        <>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={35}>
          <ModalBody color={'white'} borderRadius={30} bg={'gray.700'}>
            <VStack>
              <VStack className="bounceObj" padding={10}>
                  <Heading fontFamily={'gotham-rounded-bold'}>Congratulations!!</Heading>
                  <Text fontSize={'100px'}>🎉</Text>
              </VStack>
              <Button as={'a'} target="_blank" href={`https://bscscan.com/tx/${data?.hash}`} fontFamily={'gotham-rounded-bold'} size={'lg'} colorScheme="orange">Check Tx</Button>
              <Heading textAlign={'center'} color={'orange'} size={'sm'}>Your Peepers is in your wallet!</Heading>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
        <Button onClick={handleClick} isLoading={isLoading} borderRadius={25} w={'100%'} fontFamily={"gotham-rounded-bold"} colorScheme="orange">
        Available to Withdraw: {WeiToEth(props.available ?? "0").toString()}
    </Button>
    </>

    )
}

export const WithdrawForm = () => {
    const {address} = useAccount()
    const [date, setDate] = useState<Date>()
    const {data: allocated} = useAllocated({address: address ?? ""})
    const {data: available} = useAvailable({address: address ?? ""})
    const {data: startTime} = useStartTime()
    useEffect(() => {
       if(startTime) setDate(new Date(Number((startTime as bigint).toString() + '000')))
    }, [startTime])
    if(!allocated || Number(allocated) == 0) return <></>
    return (
        <VStack w={'100%'}>
            <Image zIndex={1000} marginBottom={'-2rem'} h={'5rem'} src={TokenImage} />
            <Card paddingTop={'1rem'} borderRadius={30} w={"100%"} bg={"gray.700"}>
            <CardBody color={'white'}>
                <VStack spacing={2}>
                <HStack alignItems={'center'}>
                <Heading fontFamily={"gotham-rounded-bold"} size={'md'}>Your Allocations: ${WeiToEth(allocated as any ?? "0").toString()} PEEPER</Heading>
                <Image src={PeeperLogo} w={8} h={8} />
            </HStack>
            <Heading fontFamily={"gotham-rounded-bold"} size={'md'}>Withdraw Period Starts In:</Heading>
                {date && <Countdown targetDate={date} size="4xl"/>}
            </VStack>
                {available as any && Number(available) > 0 && <ClaimButton available={available as any}/>}
            </CardBody>
        </Card>
      </VStack>
    )
}