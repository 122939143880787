import React, { ReactNode, useCallback, useEffect, useState } from 'react';

interface CountdownProps {
  targetDate: Date;
  size?: string
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, size }) => {
  const formatDigit = (digit: number): string => {
    return digit < 10 ? `0${digit}` : digit.toString();
  };

  const calculateTimeLeft = useCallback(() => {
    const now = new Date().getTime();
    const difference = targetDate.getTime() - now;

    if (difference <= 0) {
      // Timer has reached or passed the target date
      return { days: '00', hours: '00', minutes: '00', seconds: '00' };
    }

    const days = formatDigit(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const hours = formatDigit(
      Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    const minutes = formatDigit(
      Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    );
    const seconds = formatDigit(Math.floor((difference % (1000 * 60)) / 1000));

    return { days, hours, minutes, seconds };
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft, targetDate]);

  return (
    <div className={`mb-8 flex flex-wrap gap-1 text-4xl lg:text-${size ?? '7xl'} text-primary`}>
      <CountdownNumber label='Days' number={timeLeft.days} /> :{' '}
      <CountdownNumber label='Hours' number={timeLeft.hours} />
      :
      <CountdownNumber label='Minutes' number={timeLeft.minutes} /> :{' '}
      <CountdownNumber label='Seconds' number={timeLeft.seconds} />
    </div>
  );
};

type CountdownNumberProps = {
  number: ReactNode;
  label: string;
};
function CountdownNumber({ label, number }: CountdownNumberProps) {
  return (
    <div className='tabular-nums' style={{fontFamily: 'gotham-rounded-bold'}}>
      <div className='flex flex-col items-center'>
        <p>{number}</p>
        <p className='text-lg text-white text-center'>{label}</p>
      </div>
    </div>
  );
}

export default Countdown;
